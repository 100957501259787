<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">6S管理软件</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP" style="text-indent: 2em">
        用于6S检查的落地软件，从此6S日常检查可以电子化，便于记录留存，全公司各部门负责人可实时看到检查情况，整改措施自动跟踪，6S检查人员可脱离大部分文书编制、发出报告、跟进整改等琐碎事项，专心进行检查及记录。
      </p>
      <br />
      <br />
      <p class="PTitle LeftBor">
        解决客户的什么问题：
      </p>
      <p class="SmallP">
        每次花费大量时间编制检查内容
        <br />
        每次检查后要费时间去追踪、跟进、催促整改
        <br />
        留下一大堆纸张记录
      </p>
      <br />
      <p class="PTitle LeftBor">
        我们公司的解决方案：
      </p>
      <p class="SmallP">
        使用数字化6S检查平台
        <br />
        检查内容自动生成
        <br />
        检查记录电子化
        <br />
        系统自动跟踪整改措施，软件平台没有人情可讲
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>